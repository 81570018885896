<template>
  <v-container fluid>
    <v-row class="mx-auto mt-5 pb-5">
      <v-col cols="12">
        <h1>
          <router-link :to="{ name: 'AssessmentCenter' }"
            >Assessment center</router-link
          >
          | <span class="title"> Vendor Management</span>
        </h1>
      </v-col>
    </v-row>
    <v-container>
      <v-row class="mt-10">
        <v-col class="mx-auto" cols="12" xl="10">
          <app-action-panel />
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  components: {
    appActionPanel: () => import('@/components/vendor-managment/menu'),
  },
  props: {
    source: String,
  },
  data: () => ({
    dialog: false,
    drawer: null,
  }),
}
</script>
